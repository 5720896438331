import React from "react";
import "../App.css";
import { Helmet } from "react-helmet";

import HeaderRj from "../components/rj/HeaderRj";
import ClassificacaoCompletaRj from "../components/rj/ClassificacaoCompletaRj";
import TableQuesitosRj from "../components/rj/TableQuesitosRj";

function PageCarnavalRj() {
  return (
    <div className="App">
      <HeaderRj />
      <div className="container in">
        <div className="row">
          <div className="col-12 col-md-12">
            <ClassificacaoCompletaRj />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12">
            <TableQuesitosRj></TableQuesitosRj>
          </div>
        </div>
      </div>

      <Helmet>

        <meta name="author" content="Jornal O Dia" />
    
        <meta property="og:title" content="Apuração das escolas de samba do Grupo Especial do Rio de Janeiro - Editora O DIA" />
        <meta property="og:description" content="Acompanhe em tempo real a apuração das escolas de samba do Grupo Especial do Rio de Janeiro - Carnaval 2025." />
        <meta property="og:image" content="https://i0.statig.com.br/ig/images/o%20Dia%20na%20Folia-01.jpg" />
    
        <meta name="og:title" content="Apuração das escolas de samba do Grupo Especial do Rio de Janeiro - Editora O DIA" />
        <meta name="og:description" content="Acompanhe em tempo real a apuração das escolas de samba do Grupo Especial do Rio de Janeiro - Carnaval 2025." />
        <meta name="og:image" content="https://i0.statig.com.br/ig/images/o%20Dia%20na%20Folia-01.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@jornalodia" />
        <meta name="twitter:title" content="Apuração das escolas de samba do Grupo Especial do Rio de Janeiro - Editora O DIA"/>
        <meta name="twitter:description" content="Acompanhe em tempo real a apuração das escolas de samba do Grupo Especial do Rio de Janeiro - Carnaval 2025"/>
        <meta name="twitter:url" content="https://apuracao-carnaval.ig.com.br/rj"/>
        <meta name="twitter:image:src" content="https://i0.statig.com.br/ig/carnaval-2022/iguinho-folia_720.png"/>
        <meta name="description" content="Acompanhe em tempo real a apuração das escolas de samba do Grupo Especial do Rio de Janeiro - Carnaval 2025."/>
        <meta name="keywords" content="folia, apuracao, rj, carnaval, Rio de Janeiro, blocos, escola de samba, apuração, grupo especial, grupo de acesso"/>
        
      </Helmet>
    </div>
  );
}

export default PageCarnavalRj;
